import React, {useState} from 'react'
import './App.css'
import 'bootstrap/dist/css/bootstrap.css'
import ReactTable from 'react-table'
import "react-table/react-table.css";

import LoginComponent from './LoginComponent.js'
import BukkenReviewComponent from './BukkenReviewComponent.js'
import BukkenStateButton from './BukkenStateButton.js'
import BukkenEditableComponent from './BukkenEditableComponent.js'
import BukkenScoreComponent from './BukkenScoreComponent.js'

var bukkens = [
  {"id":"%E6%B0%B8%E7%A6%8F%E6%96%B9%E5%BA%AD%E5%BC%8F","url":"https://cowcamo.jp/%E6%9D%B1%E4%BA%AC%E9%83%BD/%E6%9D%89%E4%B8%A6%E5%8C%BA/%E6%B0%B8%E7%A6%8F%E6%96%B9%E5%BA%AD%E5%BC%8F","source":"cowcamo","name":"永福方庭式","date":"2019-06-24--23-03-44","price":"5,298","loanMonthlyEstimate":"148,939","kanriMonthlyCost":"17,300","maintenanceMonthlyCost":"11,900","pets":"飼育可 (細則あり※ 1住戸2匹まで / 犬猫可 / 体長50cm以内、体重10kg以内のもの)","address":"東京都杉並区永福町4-25-14","layout":"1LDK+S","area":"59.16","balconyArea":"10.97","structure":"その他","story":"地上5階建て1階部分","doors":"15","yearBuilt":"1989年3月","yearRenovated":"2019年1月","status":"空室","lastUpdated":"2019/06/21"},
  {"id":"%E8%8A%B1%E3%81%A8%E7%B7%91%E3%81%AE%E7%A7%98%E8%96%AC","url":"https://cowcamo.jp/%E6%9D%B1%E4%BA%AC%E9%83%BD/%E5%A4%A7%E7%94%B0%E5%8C%BA/%E8%8A%B1%E3%81%A8%E7%B7%91%E3%81%AE%E7%A7%98%E8%96%AC","source":"cowcamo","name":"花と緑の秘薬","date":"2019-06-24--23-04-04","price":"4,780","loanMonthlyEstimate":"134,377","kanriMonthlyCost":"38,600","maintenanceMonthlyCost":"15,900","pets":"飼育不可","address":"東京都大田区山王2-26-9","layout":"2LDK + DEN","area":"67.95","balconyArea":"","structure":"鉄筋コンクリート造","story":"地下1階付き地上3階建て3階部分","doors":"17","yearBuilt":"1974年3月","yearRenovated":"2019年4月","status":"空室","lastUpdated":"2019/06/20"}
]

var propertyOrderOverride = [
  "name",
  "progress",
  "score",
  "address",
  "price",
  "area",
  "lobby",
  "corridor",
  "story",
  "interior",
  "view",
  "noiseLevels",
  "deliveryBox",  
  "neighborhood",
]

var hideProperties = [
  "id",
  "source",
  "changes",
  "reviews",
  "uniqueId",
  "url",
]


function generateMapLink(address) {
  return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`
}

class App extends React.Component {
  constructor(props) {
    super(props)
    this.decorators = {
      'address':  row => ( <a href={generateMapLink(row.value)} target="_blank" rel="noopener noreferrer">{row.value}</a> ),
      'name':     row => ( <div>{row.value}<br /><a className="btn btn-outline-info" href={row.original.url}>({row.original.source})</a><a className="btn btn-outline-primary" href={generateMapLink(row.original.address)}>(map)</a></div> ),
      'progress': row => ( <BukkenStateButton bukken={row.original} key={row.original.uniqueId + "-state"} onStateUpdated={this.loadData} /> ),
      'score':    row => ( <BukkenScoreComponent bukken={row.original} key={row.original.uniqueId + "-score"} bukkenId={row.original.uniqueId} /> ),
      'reviewable': (row) => ( 
        <div>
          <BukkenEditableComponent key={row.original.uniqueId + "-edit"} data={row.value} bukkenId={row.original.uniqueId} property={row.column.id} />
          <BukkenReviewComponent key={row.original.uniqueId + "-review"} 
            onReviewUpdated={this.loadData}
            bukken={row.original}
            bukkenId={row.original.uniqueId} 
            user={this.state.user}
            property={row.column.id}
            review={
              (row.original.reviews && row.original.reviews[row.column.id] && this.state.user in row.original.reviews[row.column.id])
                ? row.original.reviews[row.column.id][this.state.user] 
                : 0
            }
            reviews={row.original.reviews} />
        </div> 
        ),
    }
    this.loadData = this.loadData.bind(this)
    this.state = this.makeBukkenData(bukkens, true)

    this.state.resized = [{'id': 'name', value: 250}, {'id':'state', 'value':100}]
    this.state.user = localStorage.getItem('user')
  }
  
  render() {
    return ( <div>
      <LoginComponent user={this.state.user} onUserChanged={user => this.handleUserChange(user)} />
      <ReactTable filterable={true} data={this.state.bukken} columns={this.state.columns} loading={this.state.loading} onFetchData={() => this.loadData()} showPagination={false} defaultPageSize={1000} minRows={0} defaultSorted={[{id: 'progress'}, {id: 'date', desc: true}]} />
      </div> )
  }

  loadData() {
    fetch('https://asia-northeast1-bukken-bot.cloudfunctions.net/api/bukken/')
      .then(response => response.json())
      .then(data => { this.setState(this.makeBukkenData(data)) })
    
  }

  handleUserChange(user) {
    this.setState({user: user})
  }

  makeBukkenData(data, loading=false) {
    // Create a sorting order for all bukken properties
    let bukkens = data.filter(b => b.name)
    let properties = new Set()


    bukkens.forEach((bukken) => {
      Object.keys(bukken).forEach((property) => properties.add(property))
    })
  
    // Init with property overrides
    propertyOrderOverride.forEach((property) => properties.delete(property))
  
    // Hide columns that we don't want to see
    hideProperties.forEach((property) => properties.delete(property))
  
    let columns = propertyOrderOverride.concat(Array.from(properties).sort())
      .map((col) => { return {'Header': col, 'accessor': col, 'Cell': (this.decorators[col] ? this.decorators[col] : this.decorators['reviewable'])}})
  
    console.log(`Bukken: ${JSON.stringify(bukkens)}`)
    console.log(`Filtered bukken: ${JSON.stringify(bukkens.filter(b => b.address))}`)
    
    return {
      loading: loading,
      bukken:  bukkens,
      columns: columns,
    }
  }

}

export default App;

