import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'

class BukkenScoreComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            bukkenId: props.bukkenId,
            bukken: props.bukken,
            reviews: props.bukken.reviews,
            modalVisible: false
        }
    }

    get score() {
        if (!this.state.reviews || this.state.reviews === undefined)
            return 0

        const reviewObjects = Object.values(this.state.reviews)
        console.log(JSON.stringify(reviewObjects))
        let score = 0
        for (let propReview of reviewObjects) {
        score += Object.values(propReview).reduce((sum,r) => sum+r)
        }
        return score
    }

    setModalVisible(state) {
        this.setState({modalVisible: state})
    }

    get reviews() {
        if (!this.state.reviews)
            return {}

        return this.state.reviews
    }

    renderReviewRows() {
        const reviews = this.reviews
        
        return ( 
            Object.keys(reviews).map((prop) => {
                return ( 
                    <tr>
                        <td>{prop}</td>
                        <td>{'Antti' in reviews[prop] ? reviews[prop]['Antti'] : '-'}</td>
                        <td>{'Astrid' in reviews[prop] ? reviews[prop]['Astrid'] : '-'}</td>
                    </tr>
                )
            })
        )
    }

    render() {
        return ( 
            <div>
            <Button value={this.score} key="bukkenId" onClick={() => this.setModalVisible(true)} variant={getButtonVariant(this.score)}><strong>{this.score}</strong></Button>
            <Modal show={this.state.modalVisible} onHide={() => this.setModalVisible(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.state.bukken.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Scores by user:
                    <Table striped bordered>
                        <thead>
                            <tr>
                                <th>Property</th>
                                <th>Antti</th>
                                <th>Astrid</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            this.renderReviewRows()
                        }
                        </tbody>
                    </Table>
                </Modal.Body>
                
                <Modal.Footer>
                    {this.state.bukken.uniqueId}
                </Modal.Footer>
            </Modal>
            </div>
        )
    }
}

function getButtonVariant(score) {
    if (score === 0)
        return 'light'
    else if (score > 0 && score <= 10)
        return 'outline-primary'
    else if (score > 10)
        return 'outline-success'
    else if (score >= -5 && score < 0)
        return 'outline-warning'
    else if (score < -10)
        return 'outline-alert'
}

export default BukkenScoreComponent