import React from 'react'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'

class BukkenEditableComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            bukkenId: props.bukkenId,
            property: props.property,
            data: props.data ? props.data : "",
            editing: false,
            dataWhileEditing: props.data ? props.data : ""
        }
    }

    setEditing(state) {
        this.setState({editing: state})
    }

    async handleSubmit(e) {
        e.preventDefault()
        
        const response = await fetch(
            `https://asia-northeast1-bukken-bot.cloudfunctions.net/api/bukken/${encodeURIComponent(this.state.bukkenId)}/data/${encodeURIComponent(this.state.property)}/${encodeURIComponent(this.state.dataWhileEditing)}`,
            {
                method: 'POST',
                body: {[this.state.property]: this.state.dataWhileEditing}
            })

        this.setState({data: this.state.dataWhileEditing, editing: false})
        
    }

    handleEdit(value) {
        this.setState({dataWhileEditing: value})
    }

    render() {
        return (
        <div>
            { !this.state.editing ? (
                this.state.data ? (
                    <Button key={this.state.bukkenId} size="sm" variant="outline-secondary" onClick={() => this.setEditing(true)}>{this.state.data}</Button>    
                ) : (
                    <Button key={this.state.bukkenId} size="sm" variant="light" onClick={() => this.setEditing(true)}>(click to add)</Button>
                )
            )
            : ( 
                <form action="#" onSubmit={e => this.handleSubmit(e)}>
                    <InputGroup size="sm">
                        <FormControl name="comments" placeholder="(comments)" defaultValue={this.state.dataWhileEditing} onInput={e => this.handleEdit(e.target.value)}></FormControl>
                    <InputGroup.Append>
                        <Button variant="primary" type="submit">OK</Button>
                    </InputGroup.Append>
                    </InputGroup>
                </form>
            )}
        </div>
        )
    }
}

export default BukkenEditableComponent