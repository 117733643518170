import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'

const REVIEW_CLASSES = {
  '-2': 'danger',
  '-1': 'warning',
  '0': 'light',
  '+1': 'primary',
  '+2': 'success'
}

function findVariantForReview(review, active) {  
  return `${REVIEW_CLASSES[review]}${active ? '' : '-outline'}`
}

class BukkenReviewComponent extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        bukken: props.bukken,
        bukkenId: props.bukkenId,
        property: props.property,
        reviews: props.reviews ? props.reviews : {},
        review: props.review,
        user: props.user,
        showModal: false
      }
      this.onReviewUpdated = props.onReviewUpdated ? props.onReviewUpdated : () => {}

      this.handleClick = this.handleClick.bind(this)
    }

    async setReview(bukkenId, user, property, review) {
        const response = await fetch(
            `https://asia-northeast1-bukken-bot.cloudfunctions.net/api/bukken/${encodeURIComponent(this.state.bukkenId)}/review/${encodeURIComponent(user)}/${encodeURIComponent(property)}/${encodeURIComponent(review)}`,
            {method: 'POST'})
        this.setState({'review': review})
        this.onReviewUpdated(user, property, review)
    }

    score() {
      /*return (this.state.reviews[this.state.property] && this.state.user in this.state.reviews[this.state.property])
        ? this.state.reviews[this.state.property][this.state.user] 
        : 0*/
      return parseInt(this.state.review)
    }

    scoreFormated() {
      return ((this.state.review > 0 ? '+':'') + parseInt(this.state.review))
    }

    handleClick(review) {
        this.setReview(this.state.bukkenId, this.state.user, this.state.property, review)
        this.setModalVisible(false)
    }

    setModalVisible(state) {
      this.setState({showModal: state})
    }

    render() {
      return (
        <div>
          <Button variant={findVariantForReview(this.scoreFormated(), true)} onClick={() => this.setModalVisible(true)} className={`review-${this.score()}`}>
            { this.scoreFormated() }
          </Button>
          <Modal show={ this.state.showModal } onHide={() => this.setModalVisible(false) }>
            <Modal.Header closeButton>
              <Modal.Title>{this.state.bukken.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body><center>Review for {this.state.property}: <br />
            <ButtonGroup>
              { 
                ['-2', '-1', '0', '+1', '+2'].map(i => {
                  return (
                <Button key={this.state.bukkenId + `-review${i}`} onClick={(e) => this.handleClick(i)} variant={findVariantForReview(i, parseInt(i) === this.score())}>
                  { i }
                </Button>
                )})
              }
            </ButtonGroup></center>
          </Modal.Body>
          <Modal.Footer>
            {this.state.bukken.uniqueId}
          </Modal.Footer>
        </Modal>
        </div>
        
      )
    }
  }

  export default BukkenReviewComponent