import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

class LoginComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            user: props.user ? props.user : null ,
            show: props.show ? props.show : false
        }
        this.setUser = this.setUser.bind(this)
        this.onUserChanged = props.onUserChanged ? props.onUserChanged : () => {}
    }
    render() {
        return ( 
            <Modal show={this.state.show}  onHide={() => this.setVisible(false) }>
                <Modal.Header closeButton>
                    <Modal.Title>Select user</Modal.Title>
                </Modal.Header>
                <Modal.Body>Select user for reviews:<br />
                <Button onClick={() => this.handleUserSelection('Astrid')}>Astrid &lt;3</Button><br/>
                <Button onClick={() => this.handleUserSelection('Antti')}>Antti</Button>
                </Modal.Body>
                <Modal.Footer>
                    Your current selection: {this.state.user}
                </Modal.Footer>
            </Modal> 
        )
    }

    get user() {
        return this.state.user
    }

    handleUserSelection(user) {
        this.setUser(user)
        this.setVisible(false)
    }

    setUser(user) {
        localStorage.setItem('user', user)
        this.setState({user: user, show: this.state.visible})
        this.onUserChanged(user)
    }

    setVisible(visible) {
        this.setState({user: this.state.user, show: visible})
    }

    componentDidMount() {
        if (this.state.user == null)
            this.setState({show: true})
    }
}

export default LoginComponent