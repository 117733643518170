import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

var statesAndStyles = {
    'Pending': 'light',
    "Let's apply": 'outline-primary',
    'Applied': 'outline-secondary',
    'Visited': 'outline-success',
    'Rejected': 'dark',
    'Unavailable': 'dark',
    'Duplicate': 'dark'
  }

class BukkenStateButton extends React.Component {
    constructor(props) {
      super(props)
      this.onStateUpdated = props.onStateUpdated
      this.state = {
        bukken: props.bukken,
        showModal: false
      }
  
      this.setModalVisible = this.setModalVisible.bind(this)
    }
  
    setModalVisible(value) {
      this.setState({bukken: this.state.bukken, showModal: value})
    }
  
    async handleStateClick(id, state) {
      this.setModalVisible(false)
      await this.updateBukkenProgress(this.state.bukken.uniqueId, state)
      if (this.onStateUpdated != null)
        this.onStateUpdated(state)
    }
  
    async updateBukkenProgress(id, progress) {
      const response = await fetch(
        `https://asia-northeast1-bukken-bot.cloudfunctions.net/api/bukken/${encodeURIComponent(id)}/progress/${encodeURIComponent(progress)}`,
        { method: 'POST' })
      return response 
    }
  
    render() {
      return (
        <div>
        <Button onClick={() => this.setModalVisible(true)} variant={statesAndStyles[this.state.bukken.progress] ? statesAndStyles[this.state.bukken.progress] : statesAndStyles['Pending']}>{this.state.bukken.progress ? this.state.bukken.progress : "Pending"}</Button>
        <Modal show={ this.state.showModal } onHide={() => this.setModalVisible(false) }>
          <Modal.Header closeButton>
          <Modal.Title>{this.state.bukken.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>Change state to: 
            { Object.keys(statesAndStyles).map((state) => {
              return ( 
                <Button key={this.state.bukken.uniqueId} variant={statesAndStyles[state]} active={state === this.state.bukken.state}
                  onClick={() => this.handleStateClick(this.state.bukken.uniqueId, state)}>{state}</Button>
              )
            })}
          </Modal.Body>
          
          <Modal.Footer>
            {this.state.bukken.uniqueId}
          </Modal.Footer>
        </Modal>
        </div>
      )
    }
  }

  export default BukkenStateButton